<div class="jumbotron">
    <div class="space"></div>
    <div class="row">
        <div class="col-md-6">
            <a class="float-right home-link" (mouseover)="changeImage('pers')">Know me Personally..!</a>
        </div>
        <div class="col-md-6">
            <a class="float-left home-link" (mouseover)="changeImage('prof')">Lets get Profeessional</a>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 runner">
            <a class="text-xs-center home-link" (mouseover)="changeImage('runner')">Here is another runner...</a>
        </div>
    </div>
</div>