import { Component, OnInit } from '@angular/core';
import { StyleManagerService } from '../style-manager-service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  private themeWrapper = document.querySelector('body').getElementsByClassName('screen')[0];

  private themes = [
    { primary: '#673AB7', accent: '#FFC107', name: 'pers', isDark: false, isDefault: true },
    { primary: '#3F51B5', accent: '#E91E63', name: 'runner', isDark: false },
    { primary: '#3F51B5', accent: '#E91E63', name: 'prof', isDark: false }
  ];


  constructor(private styleManager: StyleManagerService) { }

  ngOnInit() {
  }

  changeImage(themeName) {
    // alert('sdas');
    // this.themeWrapper.setAttribute('style', '{background: url(\'../assets/bg_1.jpg\') no-repeat center center fixed}');
    const theme = this.themes.find(currentTheme => currentTheme.name === themeName);
    if (!theme) { return; }
    if (theme.isDefault) {
      this.styleManager.removeStyle('theme');
    } else {
      this.styleManager.setStyle('theme', `/assets/themes/${theme.name}.css`);
    }
  }

}
